.test {
    column-gap: 67px;
}
.testTests {
    width: 70%;
    row-gap: 20px;
}
.background {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: linear-gradient(0deg, rgba(40, 40, 40, 0.03) 0%, rgba(40, 40, 40, 0.03) 100%), #FFF;
    z-index: -1;
}