.navigationBar {
    width: max-content;
    min-width: 285px;
    height: max-content;
    box-sizing: border-box;
    padding: 24px;
    row-gap: 24px;
    background-color: var(--basic-white);
    border-radius: 10px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
}