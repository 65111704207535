.projectsDropdown {
  position: relative;
}
.projectsDropdownIcon {
  width: 44px;
  height: 44px;
  background: transparent;
  cursor: pointer;
  column-gap: 4px;
  padding: 4px 5px;
  box-sizing: border-box;
  transition-duration: 0.3s;
}
.projectsDropdownIconBlock {
  row-gap: 4px;
}
.projectsDropdownIconLeftSmallBlock {
  width: 16px;
  height: 13px;
  border-radius: 5px;
  background: var(--accent-1-light);
}
.projectsDropdownIconLeftSmallBlockActive {
  background: var(--accent-g-primary);
}
.projectsDropdownIconLeftLargeBlock {
  width: 16px;
  height: 20px;
  border-radius: 5px;
  background: var(--accent-1-light);
}
.projectsDropdownIconActive .projectsDropdownIconLeftSmallBlock {
  background: var(--accent-g-primary);
}
.projectsDropdownIconActive .projectsDropdownIconLeftLargeBlock {
  background: var(--accent-g-primary);
}
.projectsDropdownMenu {
  width: 279px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
  background-color: var(--basic-white);
  position: absolute;
  z-index: 2;
  top: 54px;
  overflow: hidden;
}
.projectsDropdownMenuHeading {
  margin: 12px 24px;
}
.projectsDropdownMenuItem {
  width: 100%;
  padding: 12px 24px;
  box-sizing: border-box;
  cursor: pointer;
  column-gap: 10px;
  align-items: center;
  transition-duration: 0.3s;
}
.projectsDropdownMenuItemIcon {
  font-size: 24px;
  color: var(--accent-1-primary);
}
.projectsDropdownMenuItemDescriptionSmallText {
  font-size: 12px;
  font-weight: 400;
  color: var(--text-secondary);
}
.projectsDropdownMenuItemDisabled {
  pointer-events: none;
}
.projectsDropdownMenuItemDisabled .projectsDropdownMenuItemIcon {
  font-size: 24px;
  color: var(--text-secondary);
}
.projectsDropdownMenuItemDisabled .projectsDropdownMenuItemDescriptionText {
  color: var(--text-secondary);
}
.projectsDropdownMenuItemDisabled
  .projectsDropdownMenuItemDescriptionSmallText {
  font-size: 12px;
  font-weight: 400;
  color: var(--text-secondary);
}
.projectsDropdownMenuItem:hover,
.projectsDropdownMenuItemActive {
  background-color: var(--accent-1-primary);
}
.projectsDropdownMenuItem:hover .projectsDropdownMenuItemIcon,
.projectsDropdownMenuItemActive .projectsDropdownMenuItemIcon {
  color: var(--basic-white);
}
.projectsDropdownMenuItem:hover .projectsDropdownMenuItemDescriptionText,
.projectsDropdownMenuItemActive .projectsDropdownMenuItemDescriptionText {
  color: var(--basic-white);
}
.projectsDropdownMenuItem:hover .projectsDropdownMenuItemDescriptionSmallText,
.projectsDropdownMenuItemActive .projectsDropdownMenuItemDescriptionSmallText {
  color: var(--bg-dark);
}
