.passwordReset {
  width: 100%;
  row-gap: 32px;
}
.passwordReset p,
.passwordReset h2,
.passwordReset label,
.passwordReset a {
  font-family: "Onest";
}
.passwordResetHeading {
  align-self: center;
}
.passwordResetHeadingTitle {
  font-weight: 600;
  margin: 0 0 8px 0;
  text-align: center;
}
.passwordResetHeadingText {
  text-align: center;
}
.passwordResetForm {
  width: 100%;
  row-gap: 32px;
}
.passwordResetFormInputs {
  width: 100%;
  row-gap: 20px;
}
.passwordResetInput {
  margin: 6px 0 0 0;
}
.passwordResetFooterLink {
  font-size: 14px;
  font-weight: 600;
  color: var(--accent-1-primary);
}
