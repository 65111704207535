.candidateTests {
    column-gap: 20px;
}
.testsList {
    max-width: 794px;
    width: 100%;
    row-gap: 20px;
}
.testsListButtons {
    max-width: 402px;
    height: 40px;
    background-color: var(--accent-1-light);
    border: 1px solid var(--accent-1-primary);
    border-radius: 10px;
    overflow: hidden;
    margin: 16px 0 0 0;
}
.testsBlock {
    margin: 43px 0 0 0;
    row-gap: 43px;
}
.testsBlockTests {
    border-top: 1px solid var(--light-4);
    column-gap: 15px;
    flex-wrap: wrap;
}
.testsAssign {
    row-gap: 20px;
}