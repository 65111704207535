.registration {
    min-height: 100vh;
}

.registrationDescription {
    width: 30%;
    min-height: 100%;
    background-color: var(--primary-main);
    box-shadow: 7px 0px 20px 0px rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
}
.registrationDescriptionDocument {
    background: url("../../assets/svg/document.svg");
    width: 255px;
    height: 255px;
    display: inline-block;
}

.registrationText {
    max-width: 550px;
    font-size: 32px;
    font-weight: 500;
    line-height: normal;
    position: relative;
    top: 40%;
    left: 25%;
    color: var(--basic-white);
}

.registrationForm {
    width: 70%;
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 218px;
}

.form {
    max-width: 464px;
    display: flex;
    flex-direction: column;
}

.formStages {
    align-items: center;
    position: relative;
}
.formStagesStage {
    width: 22px;
    height: 22px;
    background-color: var(--light-2);
    border-radius: 22px;
    transition-duration: 0.3s;
}
.formStagesStick {
    width: 161px;
    height: 4px;
    background-color: var(--light-2);
    transition-duration: 0.3s;
}
.formStagesStageActive {
    width: 30px;
    height: 30px;
    background-color: var(--primary-main);
}
.formStagesStickActive {
    background-color: var(--primary-main);
}

.secondStageCompany {
    margin: 56px 0 41px 0;
}
.secondStageRole {
    margin: 41px 0;
}
.secondStageUserGroup {
    margin: 0 0 41px 0;
}

.settings {
    align-items: center;
    align-self: flex-end;
}

.textDark {
    color: var(--dark-2);
    margin-left: 25px;
}

.loginLink {
    width: 40px;
    height: 40px;
    background-color: var(--primary-main);
    border-radius: 10px;
    margin-left: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.headingMargin {
    margin-top: 46px;
}

.textLight {
    color: var(--light-3);
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.32px;
}
.input__label_margin {
    margin-bottom: 30px;
}
.input_validation {
    width: 464px;
    justify-content: space-between;
    align-items: center;
    position: relative;
}
.error {
    display: inline-block;
}

@media (max-width: 900px) {
    .registrationText {
        top: 30%;
        left: 10%;
        color: var(--basic-white);
    }
}