.loginHeader {
  width: 100%;
  align-items: center;
  padding: 14px 40px;
  box-sizing: border-box;
}

.loginHeaderBranding {
  column-gap: 40px;
  align-items: center;
}

.loginHeaderBrandingLogo {
  width: 170px;
  height: 44px;
  object-fit: cover;
}

.loginHeaderLanguage {
  column-gap: 12px;
  align-items: center;
  cursor: pointer;
}

.loginHeaderLanguageFlag {
  width: 20px;
  height: 20px;
  object-fit: cover;
}

.loginHeaderLanguageText {
  font-family: "Onest";
}
