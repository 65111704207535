@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon.eot?bj9bnz");
  src: url("fonts/icomoon.eot?bj9bnz#iefix") format("embedded-opentype"),
    url("fonts/icomoon.ttf?bj9bnz") format("truetype"),
    url("fonts/icomoon.woff?bj9bnz") format("woff"),
    url("fonts/icomoon.svg?bj9bnz#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-eye-off:before {
  content: "\e931";
}
.icon-eye:before {
  content: "\e932";
}
.icon-user-search:before {
  content: "\e923";
}
.icon-global:before {
  content: "\e922";
}
.icon-chart:before {
  content: "\e920";
}
.icon-money-tick:before {
  content: "\e90d";
}
.icon-box:before {
  content: "\e921";
  color: #fff;
}
.icon-checkbox-inactive:before {
  content: "\e908";
}
.icon-tick-square:before {
  content: "\e904";
}
.icon-buildings-2:before {
  content: "\e903";
}
.icon-clock:before {
  content: "\e919";
}
.icon-document-text:before {
  content: "\e91f";
}
.icon-timer:before {
  content: "\e91a";
}
.icon-close-circle:before {
  content: "\e91b";
}
.icon-add-circle:before {
  content: "\e91c";
}
.icon-trash:before {
  content: "\e91d";
}
.icon-profile-2user:before {
  content: "\e91e";
}
.icon-info-circle:before {
  content: "\e918";
  color: #e20917;
}
.icon-document-upload:before {
  content: "\e917";
}
.icon-edit-2:before {
  content: "\e916";
}
.icon-dots:before {
  content: "\e915";
}
.icon-search:before {
  content: "\e911";
}
.icon-people:before {
  content: "\e914";
}
.icon-octicon_chevron-right-16:before {
  content: "\e90e";
}
.icon-add-square:before {
  content: "\e90f";
}
.icon-filter:before {
  content: "\e910";
}
.icon-document-normal:before {
  content: "\e912";
}
.icon-home-2:before {
  content: "\e913";
  color: #9f9f9f;
}
.icon-telegram:before {
  content: "\e909";
  color: #fff;
}
.icon-arrow-down:before {
  content: "\e900";
}
.icon-clipboard-tick:before {
  content: "\e901";
}
.icon-headphones:before {
  content: "\e902";
  color: #fff;
}
.icon-message-question:before {
  content: "\e905";
  color: #fff;
}
.icon-profile-circle:before {
  content: "\e906";
}
.icon-tag-user:before {
  content: "\e907";
}
.icon-checkbox-disabled:before {
  content: "\e90a";
  color: #dfe5ee;
}
.icon-checkbox-active:before {
  content: "\e90b";
  color: rgb(223, 229, 238);
}
