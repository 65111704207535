.flex_footer {
    justify-content: space-around;
    align-items: center;
    background-color: var(--dark-3);
    width: 100%;
    padding-top: 23px;
    padding-bottom: 23px;
}
.flex_logo {
    align-items: center;
}
.text_footer {
    color: var(--light-5);
    margin-left: 37px;
}
.footer__buttons__container {
    column-gap: 15px;
}