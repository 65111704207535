.edit {
    row-gap: 20px;
}
.editMain {
    column-gap: 20px;
}
.editMainData {
    width: 100%;
    row-gap: 20px;
}
.choiceTabs {
    height: 40px;
    background-color: var(--accent-1-light);
    border: 1px solid var(--accent-1-primary);
    border-radius: 10px;
    overflow: hidden;
}
.passwordGenerate {
    column-gap: 10px;
    align-items: center;
}