.authHeader {
  width: 100%;
  align-items: center;
  padding: 14px 40px;
  box-sizing: border-box;
}

.authHeaderBranding {
  column-gap: 40px;
  align-items: center;
}

.authHeaderBrandingLogo {
  width: 170px;
  height: 44px;
  object-fit: cover;
}

.authHeaderLanguage {
  column-gap: 12px;
  align-items: center;
  cursor: pointer;
}

.authHeaderLanguageFlag {
  width: 20px;
  height: 20px;
  object-fit: cover;
}

.authHeaderLanguageText {
  font-family: "Onest";
}
