.file {
    width: 100%;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    height: 124px;
    border: 1px solid var(--text-secondary);
    background: transparent;
}
.fileImage {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    width: 100%;
    height: 90px;
    object-fit: cover;
}
.fileDragndropLabel {
    z-index: 10;
}
.imageHidden {
    display: none;
}
.fileDragndrop {
    position: relative;
    max-width: 265px;
    height: 90px;
    padding: 12px 24px;
    box-sizing: border-box;
    row-gap: 10px;
    align-items: center;
    border-radius: 10px;
    border: 1px dashed var(--text-secondary);
    background: transparent;
    overflow: hidden;
}
.fileDragndropIcon {
    font-size: 24px;
    color: var(--text-primary);
}
.fileHidden {
    display: none;
}
.fileDragndropText {
    font-size: 14px;
    text-align: center;
}
.fileDragndropTextHighlighted {
    color: var(--accent-1-dark);
}