.input {
    width: 100%;
    padding: 12px;
    background-color: var(--basic-white);
    border-radius: 10px;
    border: 1px solid var(--dividers-borders);
    color: var(--text-primary);
    font-size: 14px;
    outline: none;
    box-sizing: border-box;
}