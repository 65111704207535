.breadcrumbs {
    height: 40px;
    padding: 8px 20px;
    box-sizing: border-box;
    border-radius: 10px;
    border: 1px solid var(--dividers-borders);
    align-items: center;
    column-gap: 12px;
    width: max-content;
    background-color: var(--basic-white);
}
.breadcrumbsLink {
    display: flex;
    align-items: center;
    color: var(--text-secondary);
}
.breadcrumbsImage {
    width: 24px;
    height: 24px;
    border-radius: 69px;
}
.breadcrumbsLinkText {
    font-size: 14px;
    margin: 0 0 0 8px;
    color: var(--text-secondary);
}
.breadcrumbsLinkActive {
    color: var(--accent-1-primary);
}
.iconChevron {
    margin: 0 0 0 12px;
    transform: rotate(180deg);
}
.chevron {
    transform: rotate(180deg);
    color: var(--text-secondary);
}
.icon {
    font-size: 24px;
}