.button {
    width: 100%;
    height: 40px;
    border-radius: 10px;
    font-size: 18px;
    cursor: pointer;
    padding: 8px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.button_outline {
    background-color: var(--accent-1-light);
    color: var(--accent-1-primary);
    border: 1px solid var(--accent-1-primary);
}
.button_outline_small {
    background-color: var(--accent-1-light);
    color: var(--accent-1-primary);
    border: 1px solid var(--accent-1-primary);
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.button_transparent {
    background: transparent;
    color: var(--accent-1-primary);
    border: 2px solid var(--accent-1-primary);
}
.button_error {
    background-color: var(--error-primary);
    color: var(--basic-white);
    border: none;
}
.button_error_small {
    background-color: var(--error-primary);
    color: var(--basic-white);
    border: none;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.buttonIcon.left {
    margin: 0;
}
.buttonIcon.right {
    transform: rotate(180deg);
    margin: 0;
}
.button_white {
    background-color: var(--basic-white);
    color: var(--text-primary);
    border: 1px solid var(--text-secondary);
    max-width: max-content;
}
.button_gradient {
    background: var(--accent-g-primary);
    box-shadow: 0px 10px 12px 0px rgba(255, 151, 26, 0.12);
    color: var(--basic-white);
    border: none;
    /* max-width: max-content; */
}
.button_error_outline {
    background-color: var(--error-light);
    color: var(--error-primary);
    border: 2px solid var(--error-primary);
}
.button_gradient_small {
    width: 40px;
    height: 40px;
    background: var(--accent-g-primary);
    box-shadow: 0px 10px 12px 0px rgba(255, 151, 26, 0.12);
    color: var(--basic-white);
    border: none;
}
.buttonIcon {
    font-size: 24px;
    margin: 0 10px 0 0;
}
.button_white .buttonIcon {
    color: var(--text-primary);
}
.button_gradient .buttonIcon {
    color: var(--basic-white);
}
.button_disabled {
    background-color: var(--basic-white);
    color: var(--text-secondary);
    border: 1px solid var(--dividers-borders);
}
.button_disabled_small {
    width: 40px;
    height: 40px;
    background-color: var(--basic-white);
    color: var(--text-secondary);
    border: 1px solid var(--dividers-borders);
}
.button_dark {
    background-color: var(--text-primary);
    color: var(--basic-white);
    border: none;
}
.button_dark_small {
    width: 40px;
    height: 40px;
    background-color: var(--text-primary);
    color: var(--basic-white);
    border: none;
}
.button_dark_small .buttonIcon {
    margin: 0;
}
.button_gradient_small .buttonIcon {
    margin: 0;
}
.button_error_small .buttonIcon {
    margin: 0;
}
