.create {
    width: 100%;
    row-gap: 20px;
}
.createMain {
    width: 100%;
    column-gap: 20px;
}
.createMainRightside {
    row-gap: 20px;
    width: 100%;
}