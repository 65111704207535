.dropdown {
    padding: 14px 30px;
    height: 48px;
    box-sizing: border-box;
    background: var(--light-1);
    border-radius: 10px;
    border: 1px solid var(--light-2);
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.downArrow {
    font-size: 24px;
}