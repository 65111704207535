.departments {
    row-gap: 20px;
}
.departmentsMain {
    column-gap: 20px;
}
.viewMainData {
    width: 100%;
    row-gap: 20px;
}
.viewMainDataRows {
    width: 100%;
    padding: 24px;
    row-gap: 20px;
    border-radius: 10px;
    border: 1px solid var(--dividers-borders);
    background: var(--basic-white);
    box-sizing: border-box; 
}
.viewMainDataRowsDepartments {
    box-sizing: border-box;
    row-gap: 20px;
}
.viewMainDataRowsDepartmentsSingle {
    border-bottom: 1px solid var(--dividers-borders);
    width: calc(100% + 48px);
    margin-left: -24px;
    margin-right: -24px;
    padding: 0 24px 20px 24px;
    row-gap: 4px;
    box-sizing: border-box;
}
.viewMainDataRowsDepartmentsSingleLast {
    border: none;
}
.viewMainDataRowsDepartmentsSingleText {
    font-size: 16px;
}
.viewMainDataRowsDepartmentsSingleInput {
    column-gap: 10px;
    align-items: center;
    box-sizing: border-box;
}