.progress {
    max-width: 333px;
    padding: 33px 39px 30px 40px;
    background-color: var(--dark-3);
    border-radius: 20px;
    height: max-content;
}
.progressHeading {
    color: var(--basic-white);
    margin: 0 0 19px 0;
}
.progressCheckboxes {
    column-gap: 10px;
    flex-wrap: wrap;
    margin: 0 0 31px 0;
}
.checkbox {
    font-size: 34px;
}