@font-face {
  font-family: "Raleway";
  src: url("./assets/fonts/Raleway-Regular.ttf") format("ttf"),
    url("./assets/fonts/Raleway-Regular.woff") format("woff");
}

@font-face {
  font-family: "Onest";
  src: url("./assets/fonts/Onest-Regular.ttf") format("ttf"),
    url("./assets/fonts/Onest-Regular.woff") format("woff");
}

.App {
  text-align: center;
}

* {
  font-family: "Raleway", sans-serif;
  margin: 0;
  padding: 0;
}

/*BASIC STYLES*/
h1 {
  color: var(--text-primary);
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

h2 {
  color: var(--text-primary);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

h3 {
  color: var(--text-primary);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

p,
.text__label {
  color: var(--text-primary);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.text-bold {
  font-weight: bold;
}

.link {
  text-decoration: none;
}

a {
  text-decoration: none;
}

/*FLEX*/
.flex__2 {
  display: flex;
  justify-content: space-between;
}

.flex__column {
  display: flex;
  flex-direction: column;
}

.flex {
  display: flex;
}

/*INPUT*/
.input {
  width: 100%;
  height: 44px;
  padding-left: 17px;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid var(--dividers-borders);
  font-size: 16px;
  background: var(--basic-white);
  color: var(--basic-black);
}
.input_error {
  border: 1px solid var(--error-primary);
}
.password-input-password {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 17px;
}
.input_password {
  border: none;
  outline: none;
  width: 100%;
  background: transparent;
  font-size: 16px;
}
.input-icon {
  font-size: 24px;
  color: var(--text-secondary);
  cursor: pointer;
}
.input:disabled {
  background-color: transparent;
}
.input_company {
  width: 377px;
  margin: 0;
}
.input_medium {
  width: 464px;
  margin-top: 12px;
}

.input.small {
  width: 100%;
  max-width: 183px;
  height: 34px;
  margin: 0;
}

.input_registration {
  width: 100%;
  height: 25px;
  border: none;
  border-bottom: 1px solid var(--basic-black);
  margin-top: 12px;
  font-size: 20px;
}

.input_registration:focus {
  outline: none;
}

.input::placeholder {
  opacity: 0.4000000059604645;
}

.input:focus {
  border: 1px solid var(--light-2);
  outline: none;
}

.validation__error {
  position: absolute;
  right: 15px;
  width: 9px;
  height: 9px;
}

/* LOCAL PRELOADER STYLES */
.preloader {
  position: relative;
}
.preloader_dropdown {
  width: 100%;
  height: 300px;
  background-color: rgba(40, 40, 40, 0.02);
}
