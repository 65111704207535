.searchDropdown {
    width: 100%;
    row-gap: 8px;
    position: relative;
}

.dropdown {
    width: 100%;
    height: max-content;
    max-height: 272px;
    background-color: var(--basic-white);
    border: 1px solid var(--dividers-borders);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    row-gap: 8px;
    padding: 8px 12px;
    box-sizing: border-box;
    position: absolute;
    top: 48px;
    overflow-y: hidden;
    z-index: 10;
}

.dropdownHeading {
    font-size: 14px;
}

.dropdownList {
    row-gap: 4px;
    overflow-y: auto;
    padding-right: 6px;
}

.dropdownList::-webkit-scrollbar {
    display: none;
}

.thumb {
    position: absolute;
    top: 0;
    right: 0;
    width: 6px;
    height: 100px;
    background-color: var(--text-secondary);
    border-radius: 60px;
    cursor: pointer;
}

.dropdownListItem {
    width: 100%;
    height: 32px;
    padding: 4px 8px;
    box-sizing: border-box;
    align-items: center;
    column-gap: 6px;
    border-radius: 6px;
    cursor: pointer;
}
.dropdownListItem:hover {
    background-color: var(--accent-1-light);
}
.dropdownListItemImage {
    width: 24px;
    height: 24px;
    object-fit: cover;
}
.dropdownListItemText {
    font-size: 14px;
}

.search {
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    border-radius: 10px;
    border: 1px solid var(--text-secondary);
    position: relative;
    padding: 8px 12px 8px 48px;
}

.searchFocused {
    border: 1px solid var(--accent-1-primary);
}

.searchFocused .searchInput {
    color: var(--accent-1-primary);
}

.searchFocused .searchIcon {
    color: var(--accent-1-primary);
}

.searchIcon {
    font-size: 24px;
    position: absolute;
    top: 8px;
    left: 12px;
}

.searchInput {
    width: 100%;
    height: 100%;
    font-size: 18px;
    font-weight: 500;
    border: none;
    outline: none;
}