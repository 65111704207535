.edit {
    width: 100%;
    row-gap: 20px;
}
.editMain {
    width: 100%;
    column-gap: 20px;
}
.editMainRight {
    width: 100%;
    row-gap: 20px;
}