:root {
    /* NEW DESIGN */
    --accent-1-primary: #FA8814;
    --accent-1-dark: #DF7407;
    --accent-g-primary: linear-gradient(135deg, #FF981B 0%, #EC5D01 100%, #EE6103 100%);
    --dividers-borders: #E6E6E6;
    --text-primary: #18181A;
    --basic-white: #ffffff;
    --text-secondary: #9F9F9F;
    --accent-1-light: #FFF1E3;
    --error-primary: #E20917;
    --error-light: #FFF4F5;
    --bg-dark: #F9F9F9;

    /* OLD DESIGN */
    --basic-black: #000000;
    --light-1: #FAFDFF;
    --light-2: #E8E8E8;
    --light-3: #8D8D8D;
    --light-4: #C4C4C4;
    --light-5: #A9A9A9;
    --light-6: #999999;
    --light-7: #F6F6F6;
    --light-8: #909DA5;
    --light-9: #6C6C6C;
    --light-10: #F8F7F8;
    --light-11: #F4F6F9;
    --flat-grey-00: #6C7B8A;
    --tests-statistics: #5FC9E9;
    --primary-main: #FF971A;
    --primary-secondary: #EC5D01;
    --companies-numbers: #1ED7A6;
    --dark-1: #3D3C41;
    --dark-2: #233D4D;
    --dark-3: #18181A;
    --dark-4: #505050;
    --dark-5: #282828;
    --flat-dark-02: #2F2F3E;
}