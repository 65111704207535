.auth {
  background-color: var(--bg-dark);
  height: 100vh;
}
.authPage {
  row-gap: 80px;
  align-items: center;
}
.authWrapper {
  width: 100%;
  max-width: 440px;
  padding: 32px;
  box-sizing: border-box;
  background-color: var(--basic-white);
  border-radius: 16px;
  box-shadow: 0px 4px 8px 0px #00000014;
}
