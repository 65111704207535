.ldsHourglass {
    display: inline-block;
    position: fixed;
    z-index: 10;
    top: 50%;
    right: 50%;
    transform: translateX(50%);
    width: 80px;
    height: 80px;
  }
  .ldsHourglass:after {
    content: " ";
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    margin: 8px;
    box-sizing: border-box;
    border: 32px solid var(--accent-1-primary);
    border-color: var(--accent-1-primary) transparent var(--accent-1-primary) transparent;
    animation: ldsHourglass 1.2s infinite;
  }
  @keyframes ldsHourglass {
    0% {
      transform: rotate(0);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
      transform: rotate(900deg);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
      transform: rotate(1800deg);
    }
  }

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--basic-white);
}

.preloader .ldsHourglass,.overlay {
  position: absolute;
  background-color: transparent;
}
.preloader .overlay,.ldsHourglass {
  position: absolute;
}