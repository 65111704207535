.tableHeader {
    width: 100%;
    height: 72px;
    box-sizing: border-box;
    justify-content: space-between;
    padding: 16px;
    align-items: center;
    /* column-gap: 183px; */
    border-bottom: 0.6px solid var(--dividers-borders);
}
.searchBar {
    max-width: 250px;
}
.tableHeaderFilters {
    column-gap: 20px;
    align-items: center;
}
.tableHeaderFiltersButton {
    max-width: max-content;
}