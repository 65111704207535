.assignTestsBox {
    padding: 24px;
    max-width: 285px;
    box-sizing: border-box;
    background-color: var(--basic-white);
    row-gap: 24px;
    height: max-content;
    box-shadow: 0px 4px 8px 0px #00000014;
    border-radius: 10px;
}
.emailsAdd {
    width: 100%;
    height: 60px;
    padding: 10px;
    box-sizing: border-box;
    background-color: var(--bg-dark);
    border-radius: 10px;
    border: 1px solid var(--dividers-borders);
}
.assignTestsBoxCandidates {
    row-gap: 10px;
}
.assignTestsBoxTests {
    row-gap: 10px;
}
.assignTestsBoxTestsHeading {
    column-gap: 12px;
    align-items: center;
}
.assignTestsBoxTestsHeadingIcon {
    font-size: 28px;
}
.assignTestsBoxDuration {
    row-gap: 10px;
}
.assignTestsBoxDurationHeading {
    align-items: center;
    column-gap: 12px;
}
.assignTestsBoxDurationHeadingIcon {
    font-size: 28px;
}
.assignTestsBoxDurationData {
    width: 100%;
    height: 80px;
    row-gap: 8px;
    box-sizing: border-box;
    padding: 4px 8px;
    background-color: var(--basic-white);
    border-radius: 10px;
    border: 1px solid var(--accent-1-primary);
}
.assignTestsBoxDurationDataTests {
    align-items: center;
    column-gap: 12px;
}
.assignTestsBoxDurationDataTime {
    align-items: center;
    column-gap: 12px;
}
.assignTestsBoxDurationDataIcon {
    font-size: 24px;
    color: var(--accent-1-primary);
}
.assignTestsBoxCandidatesHeading {
    align-items: center;
    column-gap: 12px;
}
.assignTestsBoxCandidatesHeadingIcon {
    font-size: 28px;
}
.assignTestsBoxTabs {
    background-color: var(--accent-1-light);
    border: 1px solid var(--accent-1-primary);
    border-radius: 10px;
    height: 40px;
}
.assignTestsBoxButtons {
    width: 100%;
    row-gap: 20px;
}
.assignTestsBoxButtonsButton {
    width: 100%;
}
.emails {
    row-gap: 10px;
    align-items: flex-start;
}
.candidateInfo {
    align-items: center;
    column-gap: 8px;
}
.candidateInfoText {
    color: var(--light-3);
    margin-right: 13px;
    width: 15px;
}
.selectedTests {
    padding: 26px 0 0 0;
    border-top: 1px solid var(--light-4);
    row-gap: 22px;
}
.selectedTestsHeader {
    justify-content: space-between;
    align-items: center;
}
.selectedTestsTiming {
    align-items: center;
    margin-left: 49px;
    margin-right: 18px;
    padding: 7px 11px;
    column-gap: 12px;
    background-color: var(--basic-white);
    border-radius: 10px;
}
.documentText {
    background: url("../../../assets/svg/document-text.svg");
    width: 16px;
    height: 16px;
    display: inline-block;
    margin-right: 5px;
}
.timer {
    background: url("../../../assets/svg/timer.svg");
    width: 17px;
    height: 17px;
    display: inline-block;
    margin-right: 5px;
}
.items {
    max-width: 100%;
    min-height: 40px;
    background-color: var(--bg-dark);
    border: 1px solid var(--dividers-borders);
    border-radius: 10px;
    padding: 12px;
    row-gap: 10px;
    box-sizing: border-box;
    justify-content: center;
}
.itemsText {
    color: var(--light-6);
    align-self: center;
}
.item {
    padding: 4px 8px 4px 6px;
    align-items: center;
    width: 100%;
    background-color: var(--accent-1-light);
    border-radius: 6px;
    border: 1px solid var(--accent-1-primary);
    box-sizing: border-box;
}
.testCrossIcon {
    font-size: 24px;
    cursor: pointer;
}
.naming {
    width: 80%;
    align-items: center;
}
.itemName {
    margin-left: 11px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

/* EMPLOYEE BLOCK */
.employee {
    row-gap: 24px;
}
.employeeBlock {
    row-gap: 12px;
}
.employeeCheckbox {
    column-gap: 16px;
    align-items: center;
}
.employeeCheckboxText {
    font-size: 14px;
}
.employeeHeading {
    column-gap: 12px;
    align-items: center;
}
.employeeHeadingIcon {
    font-size: 28px;
}
.itemsUsers {
    max-height: 136px;
    overflow-y: auto;
    justify-content: flex-start;
}
.itemsUsers::-webkit-scrollbar {
    display: none;
}
.itemsItem {
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    padding: 4px 6px;
    background-color: var(--basic-white);
    border-radius: 6px;
    border: 1px solid var(--dividers-borders);
}
.itemsItemName {
    column-gap: 6px;
    align-items: center;
}
.itemsItemNameImage {
    width: 24px;
    height: 24px;
    border-radius: 69px;
    border: 1px solid var(--accent-1-primary);
}
.itemsItemNameText {
    font-size: 14px;
}