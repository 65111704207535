.candidate {
    width: 100%;
    min-height: 134px;
    padding: 10px;
    background-color: var(--bg-dark);
    border: 1px solid var(--dividers-borders);
    border-radius: 10px;
    row-gap: 4px;
    box-sizing: border-box;   
}
.candidateNumber {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
}
.candidateNumberText {
    font-size: 16px;
    font-weight: 500;
}
.candidateNumberIcon {
    font-size: 20px;
    color: var(--error-primary);
    cursor: pointer;
}
.candidateInputs {
    width: 100%;
    row-gap: 10px;
}
.candidateButtons {
    row-gap: 10px;
}