.flex__column_main {
    min-height: 100vh;
    align-items: center;
    background-color: var(--bg-dark);
}
.content {
    width: 100%;
    flex-grow: 1;
    margin: 52px 0 34px 0;
    padding: 0 120px;
    box-sizing: border-box;
    align-self: center;
}