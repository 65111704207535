.checkbox {
    column-gap: 15px;
    align-items: center;
    cursor: pointer;
}
.hiddenCheckbox {
    display: none;
}
.hiddenCheckbox:checked ~ .checkboxInput {
    box-sizing: border-box;
    border: 5px solid var(--primary-main);
}
.checkboxInput {
    width: 22px;
    height: 22px;
    border: 1px solid var(--flat-grey-00);
    border-radius: 5px;
}
.checkboxText {
    font-size: 16px;
}