.analytics {
    width: 100%;
    row-gap: 20px;
}
.analyticsGraph {
    width: 100%;
    min-height: 416px;
    padding: 16px;
    box-sizing: border-box;
    row-gap: 20px;
    background-color: var(--basic-white);
    border-radius: 10px;
    border: 1px solid var(--dividers-borders);
}
.analyticsGraphHeader {
    padding: 16px 0;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.analyticsGraphHeaderTabs {
    width: 40%;
    height: 40px;
    background-color: var(--accent-1-light);
    border-radius: 10px;
    border: 1px solid var(--accent-1-primary);
    box-sizing: border-box;
}
.analyticsGraphBlock {
    width: 100%;
}
.analyticsFlex {
    width: 100%;
    column-gap: 20px;
}
.analyticsFlexScatter {
    width: 50%;
    min-height: 384px;
    background-color: var(--basic-white);
    border-radius: 10px;
    border: 1px solid var(--dividers-borders);
    padding: 16px;
    box-sizing: border-box;
    row-gap: 16px;
}
.analyticsFlexScatterHeading {
    margin: 16px 0 0 0;
}