.header {
    width: 100%;
    padding: 16px;
    height: 72px;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    background-color: var(--accent-1-light);
    border-radius: 10px;
    border: 1px solid var(--accent-1-primary);
}
.headerNaming {
    column-gap: 20px;
    align-items: center;
}
.headerNamingImage {
    width: 40px;
    height: 40px;
    border-radius: 69px;
}
.headerNamingName {
    align-items: flex-start;
}
.headerNamingNameHeader {
    font-size: 16px;
}
.headerNamingNameActive {
    font-size: 14px;
    color: var(--accent-1-dark);
}
.headerButtons {
    column-gap: 20px;
}