.navigationBarItem {
    width: 100%;
    max-width: 220px;
    border-radius: 8px;
    background-color: transparent;
    padding: 6px 8px;;
}
.navigationBarItemImage {
    width: 28px;
    height: 28px;
    object-fit: cover;
}
.navigationBarItemActive {
    background-color: var(--accent-1-light);
}
.navigationBarItemIcon {
    font-size: 28px;
    color: var(--text-primary);
}
.navigationBarItemFlex {
    align-items: center;
}
.navigationBarItemText {
    color: var(--text-primary);
    margin-left: 12px;
}
.navigationBarItemActive .navigationBarItemIcon {
    color: var(--accent-1-primary);
}
.navigationBarItemActive .navigationBarItemText {
    color: var(--accent-1-primary);
}