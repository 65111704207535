.testBlock {
    width: 100%;
    background-color: var(--basic-white);
    row-gap: 51px;
    border-radius: 20px;
    padding: 39px 35px 49px 35px;
    position: relative;
}

.testBlockHeading {
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.testBlockOptions {
    row-gap: 35px;
}

.testBlockNumber {
    position: absolute;
    top: 39px;
    right: 35px;
    column-gap: 6px;
    align-items: center;
}

.testBlockNumberCurrent {
    min-width: 31px;
    height: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background-color: rgba(198, 198, 198, 0.70);
    backdrop-filter: blur(7px);
}

.testBlockNumberCurrentText {
    font-size: 11px;
    color: var(--basic-white);
}

.testBlockNumberTotal {
    font-size: 11px;
    color: var(--light-9);
}