.test {
    max-width: 440px;
    width: 45%;
    height: 124px;
    box-sizing: border-box;
    padding: 24px;
    background-color: var(--basic-white);
    border-radius: 10px;
    margin: 16px;
    cursor: pointer;
}
.testChecked {
    border: 1px solid var(--accent-1-primary);
    box-shadow: 0px 10px 12px 0px rgba(255, 151, 26, 0.12);
}
.testCheckbox {
    column-gap: 24px;
    align-items: center;
}
.testCheckboxIcon {
    font-size: 20px;
}
.testCheckboxIconTicked {
    color: var(--accent-1-primary);
}
.testCheckboxText {
    font-size: 16px;
}
.testDataBlock {
    column-gap: 16px;
    margin: 24px 0 0 0;
}
.checkboxHidden {
    display: none;
}
.testData {
    padding: 6px 8px;
    column-gap: 8px;
    align-items: center;
    background-color: var(--accent-1-light);
    border-radius: 6px;
}
.testDataIcon {
    font-size: 20px;
    color: var(--accent-1-primary);
}
.testDataText {
    font-size: 14px;
}