.choiceTab {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 12px 24px;
    background: transparent;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.choiceTabText {
    color: var(--text-primary);
    font-size: 14px;
}
.choiceTabActive {
    background-color: var(--accent-1-primary);
    border: 1px solid var(--accent-1-primary);
}
.choiceTabActive .choiceTabText {
    color: var(--basic-white);
}