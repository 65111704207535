.view {
    row-gap: 20px;
    width: 100%;
}
.viewMain {
    column-gap: 20px;
    width: 100%;
}
.viewMainData {
    width: 100%;
    row-gap: 20px;
}
.viewMainDataQuestions {
    width: 100%;
    row-gap: 20px;
}