.flex_header {
    width: 100%;
    justify-content: space-around;
    align-items: center;
    padding-top: 24px;
    padding-bottom: 24px;
    background-color: var(--text-primary);
}
.flex_menu {
    column-gap: 68px;
    align-items: center;
}
.header_link.active {
    color: var(--accent-1-primary);
}
.header_link {
    color: var(--basic-white);
}
.logout {
    display: inline-block;
    width: 30px;
    height: 30px;
}