.block {
    width: 100%;
    padding: 24px;
    box-sizing: border-box;
    row-gap: 20px;
    border-radius: 10px;
    background-color: var(--basic-white);
    border: 1px solid var(--dividers-borders);
}
.blockList {
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
}
.blockListItem {
    width: 45%;
    row-gap: 4px;
}

.blockListItemLabel {
    font-size: 14px;
    color: var(--text-secondary);
}
.blockListItemValue {
    font-size: 16px;
}
.blockListItemText {
    font-size: 16px;
    color: var(--text-primary);
}
.blockListInputFull {
    width: 100%;
}
.blockListInput {
    width: 45%;
}