.profileDropdown {
    align-items: center;
    position: relative;
    cursor: pointer;
}
.profileDropdownActive .profileDropdownText {
    color: var(--accent-1-primary);
}
.iconProfileCircle_large {
    width: 44px;
    height: 44px;
    border-radius: 69px;
    border: 1px solid var(--accent-1-primary);
}
.profileDropdownText {
    margin: 0 0 0 12px;
    color: var(--basic-white);
}
.profileDropdownMenu {
    width: 200px;
    position: absolute;
    top: 54px;
    background-color: var(--basic-white);
    border-radius: 10px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
    overflow: hidden;
}
.profileDropdownMenuItem {
    background: transparent;
    padding: 12px 24px;
    column-gap: 10px;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
}
.profileDropdownMenuItem:hover {
    background-color: var(--accent-1-light);
}
.profileDropdownMenuItemIcon {
    font-size: 24px;
    color: var(--text-primary);
}
.profileDropdownMenuItemText {
    font-size: 16px;
}
.profileDropdownMenuItemBordered {
    border-top: 1px solid var(--dividers-borders);
}