.create {
    width: 100%;
    row-gap: 20px;
}
.createMain {
    width: 100%;
    column-gap: 20px;
}
.createMainRightside {
    row-gap: 20px;
    width: 100%;
}
.viewMainDataQuestions {
    width: 100%;
    row-gap: 20px;
}
.viewMainDataQuestionsAddButton {
    width: 100%;
    padding: 24px;
    box-sizing: border-box;
    background-color: var(--basic-white);
    backdrop-filter: blur(35px);
    border-radius: 10px;
    border: 1px solid var(--dividers-borders);
}