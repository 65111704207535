.login {
  background-color: var(--bg-dark);
  height: 100vh;
}
.login p,
.login h2,
.login label,
.login a {
  font-family: "Onest";
}
.loginPage {
  row-gap: 80px;
  align-items: center;
}
.loginWrapperHeading {
  align-self: center;
}
.loginHeading {
  font-weight: 600;
  margin: 0 0 8px 0;
  text-align: center;
}
.loginInstruction {
  text-align: center;
}
.loginWrapper {
  width: 100%;
  max-width: 440px;
  padding: 32px;
  box-sizing: border-box;
  background-color: var(--basic-white);
  border-radius: 16px;
  box-shadow: 0px 4px 8px 0px #00000014;
}
.loginWrapperForm {
  width: 100%;
  row-gap: 24px;
}
.loginForm {
  width: 100%;
  row-gap: 24px;
}
.loginFormInputs {
  width: 100%;
  row-gap: 20px;
}
.loginForgotPassword {
  margin: -4px;
  align-self: flex-end;
  font-size: 14px;
  font-weight: 600;
  color: var(--accent-1-primary);
}
.loginInput {
  margin: 6px 0 0 0;
}
.loginPageTexts {
  margin: -40px;
  row-gap: 36px;
}
.loginPageTextsText {
  text-align: center;
  font-size: 14px;
}
.loginPageTextsLink {
  font-size: 14px;
  font-weight: 600;
  color: var(--accent-1-primary);
}
.loginPageTextsDocsLink {
  color: var(--accent-1-primary);
}
