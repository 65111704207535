.question {
    width: 100%;
    /* padding: 20px 24px; */
    box-sizing: border-box;
    background-color: var(--basic-white);
    border-radius: 10px;
    border: 1px solid var(--dividers-borders);
    backdrop-filter: blur(35px);
    /* border-bottom: 1px solid var(--dividers-borders); */
}
.questionView {
    padding: 24px;
}
.questionBlock {
    width: 100%;
    padding: 24px;
    box-sizing: border-box;
    background-color: var(--accent-1-light);
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border: 1px solid var(--accent-1-primary);
}
.questionInput {
    column-gap: 10px;
    align-items: center;
}
.questionInputIcon {
    font-size: 40px;
    color: var(--error-primary);
    cursor: pointer;
}
.questionInputIconAdd {
    font-size: 40px;
    color: var(--accent-1-primary);
    cursor: pointer;  
}
.questionHeadingMain {
    margin: 0 0 20px 0;
}
.questionHeading {
    font-size: 14px;
    color: var(--text-secondary);
}
.questionText {
    font-size: 16px;
}
.questionAnswers {
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    margin: 20px 24px 24px 24px;
    align-items: center;
}
.questionAnswersView {
    margin: 10px 0 0 0;
    row-gap: 10px;
}
.questionAnswersSingleActive {
    padding: 10px;
    border-radius: 10px;
    border: 1px solid var(--accent-1-primary);
    background-color: var(--accent-1-light);
}
.questionAnswersSingle {
    flex: 1 0 45%;
}
.questionAnswersSingleActive .questionHeading {
    color: var(--accent-1-dark);
}
.questionLabel {
    font-size: 16px;
}
.questionAnswersSingleHeading {
    justify-content: space-between;
    align-items: center;
}
.questionAnswersSingleHeadingCheckbox {
    column-gap: 4px;
    align-items: center;
}
.questionAnswersSingleHeadingIcon {
    font-size: 20px;
    margin: 0 0 0 16px;
    color: var(--error-primary);
    cursor: pointer;
}
.questionAnswersSingleHeadingCheckboxText {
    font-size: 14px;
}
.questionAnswersSingleInput {
    column-gap: 10px;
    align-items: center;
}
.questionAnswersAddButton {
    flex: 1 0 45%;
    align-self: flex-end;
}